import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import Logo from '../../assets/img/logo.jpg'
import CardContent from '@material-ui/core/CardContent';
import { useForm } from "react-hook-form";
import MainHome from '../MainHome/MainHome';

const qs		= require( 'qs' );
const publicIp	= require( 'public-ip' );
const axios		= require( 'axios' );
const useStyles	= makeStyles( ( theme ) => ({
	root: {
		paddingBottom: '0 !important',
	},
	paper: {
		paddingTop: '24px',
		paddingBottom: '24px',
		maxWidth: '460px',
	},
	avatar: {
		margin: '0 0 15px',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing( 1 ),
	},
	submit: {
		margin: theme.spacing( 3, 0, 2 ),
		background: '#ca1f02',
		fontFamily: 'Raleway',
		fontWeight: 'bold',
		fontSize: '18px',
		marginBottom: '20px',
	},
	mtitle: {
		color: '#000',
		fontWeight: 'bold',
		fontFamily: 'Raleway',
		margin: '0 auto',
	},
	msubtitle: {
		color: '#000',
		fontWeight: 'bold',
		fontFamily: 'Raleway',
		margin: '0 auto',
		fontSize: '18px',
		marginTop: '15px'
	},
	msubtext: {
		color: '#000',
		fontWeight: 'normal',
		fontFamily: 'Raleway',
		margin: '0 auto',
		fontSize: '16px',
		marginBottom: '15px'
	},
	msubtextHighlight: {
		color: '#C00',
		fontWeight: 'bold',
		fontFamily: 'Raleway',
		margin: '0 auto',
		fontSize: '18px',
		marginTop: '15px'
	}
}));

export default function SignIn( props ) {
	// const [passwordval, setPassword]	= useState( '' );
	const [err, setErr]					= useState( false );
	const [showlogin, setShowLogin]		= useState( true );
	const [ip, setIp]					= useState( '' );
	const [errormsg, setErrormsg]		= useState( '' );
	const [fname, setFname]				= useState( '' );
	const [lname, setLname]				= useState( '' );
	const [email, setEmail]				= useState( '' );
	// const [country, setCountry]			= useState( '' );
	// const allowed_countries				= [ 'US', 'CA', 'PY' ];
	const classes 						= useStyles();
	const url_query						= qs.parse( props.location.search, { ignoreQueryPrefix: true } );
	// const backendURL					= 'http://back.sac.local/galalogin/register_reflections.php';
	const backendURL					= '/galalogin/register_reflections.php';

	useEffect( () => {
		document.body.style.background = 'transparent linear-gradient(90deg, #FCBD14 0%, #DB5725 100%) 0% 0% no-repeat padding-box';
		document.body.style.height = '100vh';

		if ( ! ip ) {
			( async () => {
				setIp( await publicIp.v4() );
			})()
		}
/*
		if ( ! country ) {
			axios.get( 'https://ipapi.co/json/' ).then( ( response ) => {
				setCountry( response.data.country );
			}).catch( ( error ) => {
				console.log( error );
			});
		}
*/
	});

	const accessDirectly = () => {
		if ( ip )
		{
			axios.post( backendURL, {
				// password: passwordval,
				ipaddress: ip
			});

			return <MainHome />;
		}
		else
		{
			return <MainHome />;
		}		
	}

	const { /*register,*/ handleSubmit } = useForm();
	const onSubmit = ( data ) => {

		axios.post( backendURL, {
			// password: passwordval,
			ipaddress: ip,
			fname: fname,
			lname: lname,
			email: email
		})
		.then( ( response ) => {
			console.log( '1', response.data.message );
			if ( response.data.success === 0 ) {
				setErr( true );
				setErrormsg( response.data.message );
			}
			else {
				setShowLogin( false );
			}
		})
		.catch( ( error ) => {
			console.log( '2', error );
		});
	}
/*
	const inputChange = ( e ) => {
		setPassword( e.target.value );
		setErr( false );
	}
*/
	const loginView = () => {
		return (
			<Container component="main" maxWidth={false} className="login-page">
				<CssBaseline />
				<div className={ classes.paper }>
					<Card className={ classes.root }>
						<CardContent className={ classes.root }>

							<div align="center" className={ classes.avatar }>
								<img src={ Logo } alt="Logo" />
							</div>

							<Typography component="h1" align="center" variant="h5" className={ classes.mtitle }>
								Welcome to our Pandemic Relief & Recovery Fundraiser.
							</Typography>

							<Typography component="h2" align="center" className={ classes.msubtitle }>
								The event will open on July 24 at 7:45PM EST
							</Typography>

							<Typography component="p" align="center" className={ classes.msubtext }>
								with a screening of videos and photos from disaster relief efforts in action.
							</Typography>

							<Typography component="h3" align="center" className={ classes.msubtextHighlight }>
								The virtual premiere show will begin at 8PM EST.
							</Typography>

							<Typography component="h3" align="center" className={ classes.msubtitle }>
								To view the show, please click "Enter" below.
							</Typography>
							<Typography component="p" align="center" className={ classes.msubtext }>
								If you'd like, you may submit your name and email <strong>(optional)</strong> to let us know you've arrived.
							</Typography>

							<form className={ classes.form } onSubmit={ handleSubmit( onSubmit ) }>
{/*
								<TextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									name="password"
									label="Access Code"
									type="text"
									autoComplete="off"
									onChange={ e => inputChange( e ) }
									value={ passwordval }
									error={ err }
									inputRef={ register({ required: true }) }
									helperText={ err ? errormsg : '' }
									/>
*/}
								<Grid container spacing={2}>
									<Grid item xs={6}>
										<TextField
											variant="outlined"
											margin="normal"
											fullWidth
											name="fname"
											label="First Name"
											type="text"
											autoComplete="off"
											onChange={ e => setFname( e.target.value ) }
											value={ fname }
											/>
									</Grid>
									<Grid item xs={6}>
										<TextField
											variant="outlined"
											margin="normal"
											fullWidth
											name="lname"
											label="Last Name"
											type="text"
											autoComplete="off"
											onChange={ e => setLname( e.target.value ) }
											value={ lname }
											/>
									</Grid>
								</Grid>

								<TextField
									variant="outlined"
									margin="normal"
									fullWidth
									name="email"
									label="Email Address"
									type="email"
									autoComplete="off"
									onChange={ e => setEmail( e.target.value ) }
									value={ email }
									/>

								<Button
									type="submit"
									fullWidth
									variant="contained"
									color="primary"
									className={ classes.submit }
									>ENTER</Button>

								<p align="center">
									Having trouble logging in? Contact us at <a href="mailto:support@shareandcare.org">support@shareandcare.org</a> or <a href="tel:+16097187065">(609) 718-7065</a> for assistance.
								</p>

							</form>
						</CardContent>
					</Card>
				</div>
			</Container>
		);
	}
/*
	const geoBlockView = () => {
		return (
			<Container component="main" maxWidth="xs" className="access-restricted">
				<CssBaseline />
				<div className={ classes.paper }>
					<Card className={ classes.root }>
						<CardContent className={ classes.root }>
							<div align="center" className={ classes.avatar }>
								<img src={ Logo } alt="Logo" />
							</div>

							<Typography component="h1" align="center" variant="h5" className={ classes.mtitle }>
								Access Restricted
							</Typography>
							
							<Typography component="h2" align="center" className={ classes.msubtitle }>
								This event is exclusive for US and Canada residents only.
							</Typography>

							<p align="center">
								Having trouble logging in? Contact us at <a href="mailto:support@shareandcare.org">support@shareandcare.org</a> or <a href="tel:(609) 718-7065">(609) 718-7065</a> for assistance.
							</p>
						</CardContent>
					</Card>
				</div>
			</Container>
		);
	}
*/
	// if ( ! allowed_countries.includes( country ) ) return geoBlockView();

	if ( url_query.access == 1 ) return accessDirectly();

	return showlogin ? loginView() : <MainHome />
  
}
