import React from 'react';

export default function Home() {
	return (
		<footer id="mu-footer" role="contentinfo">
			<div className="container">
				<div className="mu-footer-area">
					<div className="mu-footer-top">
						<div className="mu-social-media">
							<a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/shareandcarefoundation"><i className="fa fa-facebook"></i></a>
							<a target="_blank" rel="noopener noreferrer" href="https://twitter.com/scf4india"><i className="fa fa-twitter"></i></a>
							<a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/user/SCF4India/videos"><i className="fa fa-youtube"></i></a>
						</div>
					</div>
					<div className="mu-footer-bottom">
						<p className="mu-copy-right" style={{ fontSize: '15px', fontWeight: '500' }}>
							Copyright {`${ new Date().getFullYear() }`} Share and Care Foundation®.
							<span>
								All rights reserved.
								<a href="https://shareandcare.org/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
								Powered by <a rel="noopener noreferrer" href="https://pridigital.com/" target="_blank">PRI</a>.
							</span>
						</p>
					</div>
				</div>
			</div>

		</footer>
	)
}