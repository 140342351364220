import React, { useEffect } from 'react';
// import $ from "jquery";
import { makeStyles } from '@material-ui/core/styles';
import Logo from '../../assets/assets/images/logo.jpg'
import '../../assets/assets/styles/bootstrap.min.css'
import '../../assets/assets/styles/slick.css'
import '../../assets/assets/styles/theme-color/sac-2020-theme.css'
import '../../assets/assets/styles/style.min.css';


const useStyles = makeStyles( ( theme ) => ({
	collapsenav: {
		[theme.breakpoints.down( 'sm' )]: {
			background: '#fff',
			marginTop: '-16px'
		}
	},
	mumenu: {
		paddingBottom: '9px!important'
	},
	muevent: {
		fontSize: '17px'
	}
}));

export default function Header() {
	useEffect(() => {
	/*
		// Update the document title using the browser API
		window.$( '#mu-event-counter' ).countdown( '2020/10/10' ).on( 'update.countdown', function( event ) {
			var $this = $( this ).html( event.strftime( ''
			  + '<span class="mu-event-counter-block"><span>%D</span> Days</span> '
			  + '<span class="mu-event-counter-block"><span>%H</span> Hours</span> '
			  + '<span class="mu-event-counter-block"><span>%M</span> Mins</span> '
			  + '<span class="mu-event-counter-block"><span>%S</span> Secs</span>' ) );
		  });
	*/
	},[]);

	const classes = useStyles();
  
	return (
		<header id="mu-hero" role="banner" style={{ background: '#fff' }}>
			<nav className="navbar navbar-fixed-top navbar-default mu-navbar" style={{ backgroundColor: '#fff', borderColor: '#e7e7e7', padding: '5px 0', maxHeight: '75px', top: 'auto', borderBottom: 0 }}>
				<div className="container">
					<div className="navbar-header">
						<button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
							<span className="sr-only">Toggle navigation</span>
							<span className="icon-bar" />
							<span className="icon-bar" />
							<span className="icon-bar" />
						</button>
						<a className="navbar-brand" href="index.html"><img src={ Logo } alt="Share and Care" /></a>
					</div>

					<div className={`collapse navbar-collapse ${classes.collapsenav}`} id="bs-example-navbar-collapse-1">
						<ul className={`nav navbar-nav mu-menu navbar-right ${classes.mumenu}`}>
							<li><a rel="noopener noreferrer" id="cta-donate" href="https://shareandcare.salsalabs.org/donate/index.html" target="_blank">Donate Now</a></li>
						</ul>
					</div>
				</div>
			</nav>

			<div className="container">
				<div className="row">
					<div id="logo" className="col-md-12">
						<a className="navbar-brand" href="index.html"><img src={ Logo } alt="Share and Care" /></a>
					</div>
				</div>
			</div>

			<div className="mu-hero-overlay">
				<div className="container">
					<div className="mu-hero-area">
						<div className="mu-hero-featured-area">
							<div className="mu-hero-featured-content">
								<h1>THANK YOU FOR JOINING US.</h1>
								<h2>You’ve arrived at the viewing page for our Pandemic Relief & Recovery Fundraiser, honoring 39 years of humanitarian service.</h2>
								<p className={`mu-event-date-line ${classes.muevent}`}>Saturday, July 24, 2021 • 7:45PM EST</p>
{/*
								<div className="mu-event-counter-area">
									<div id="mu-event-counter"></div>
								</div>
*/}
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
	)
}