import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import img_poster from '../../assets/assets/images/header.png';

const useStyles = makeStyles( ( theme ) => ({
	videoAreaContainer: {
		padding: '100px 0',
		[theme.breakpoints.down( 'sm' )]: {
			padding: '50px 0',
			'& iframe': {
				height: '400px'
			}
		},
		[theme.breakpoints.down( 'md' )]: {
			'& iframe': {
				maxWidth: '100%',
				maxHeight: '100%'
			}
		}
	},
	videoAreaContainerLast: {
		paddingTop: '100px',
		paddingBottom: '0',
		paddingLeft: '0',
		paddingRight: '0',
		[theme.breakpoints.down( 'sm' )]: {
			paddingTop: '50px',
			paddingBottom: '0',
			paddingLeft: '0',
			paddingRight: '0',
		}
	},
}));

export default function Header() {
	const classes = useStyles();

	return (
		<main role="main">
			<section id="mu-video">
				<div className="mu-video-overlay" style={{ background: '#fff', opacity: '1' }}>
					<div className="container">
						<div className="row">
							<div className="col-md-12">
								<div className={`mu-video-area ${classes.videoAreaContainer}`}>
									{/* <h2 style={{color: '#ca1f02'}}>Watch The Event</h2> */}
									<center>
										<iframe title="S&C Event Video" width="1140" height="640" src={window.yt_url} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
									</center>
								</div>
							</div>
						</div>
					</div>
				</div>
{/*
				<div className="mu-video-content">
					<div className="mu-video-iframe-area">
						<a className="mu-video-close-btn" href="#" aria-label="Close" role="button"><i className="fa fa-times" aria-hidden="true"></i></a>
						<iframe title="Video" width="854" height="480" src="https://www.youtube.com/embed/od9ucBX96Cs" frameBorder="0" allowFullScreen></iframe>
					</div>
				</div>
*/}
			</section>
			<section id="mu-about" style={{ background: 'linear-gradient(90deg,#fcbd14,#db5725)' }}>
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="mu-about-area">
								<div className="row">
									<div className="col-md-8">
										<div className="mu-about-left">
											<img src={img_poster} alt="Event Poster" />
										</div>
									</div>
									<div className="col-md-4">
										<div className="mu-about-right">
											<h2 style={{ color: '#fff', fontWeight: '600' }}>REFLECTIONS OF KUTCHH</h2>
											<p style={{ fontSize: '15px', fontWeight: '600', color: '#fff' }}>Our Pandemic Relief & Recovery Fundraiser will feature entertainment by Parthiv Gohil and many other top performers, including soulful Gujarati songs and music set against the great white Rann of Kutchh. Witness this awe-inspiring event with a lineup of top performers at mesmerizing locations, with lively performances and celebrity cameos.</p>
											<p><a href="https://shareandcare.salsalabs.org/donate/index.html" target="_blank" rel="noopener noreferrer" className="button button-donateToday">SUPPORT THIS EVENT</a></p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</main>
	)
}