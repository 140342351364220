import React from 'react';
import Header from './Header.jsx';
import Main from './Main.jsx';
import Footer from './Footer.jsx';

export default function MainHome() {
    return (
        <div>
        	<Header />
        	<Main />
        	<Footer />
        </div>
    )
}